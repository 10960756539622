<template>
  <v-row justify="center" class="px-0 ma-0">
    <!-- Loading Dialog -->
    <v-dialog
      v-model="loadingDialog"
      overlay-opacity="0.9"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text class="py-4">
          {{ loadingMsg }}
          <v-progress-linear
            :indeterminate="uploadPercentage >= 100"
            :value="uploadPercentage"
            color="white"
            class="mb-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Validation Dialog -->
    <v-dialog
      v-model="errorDialog"
      max-width="680px"
      opacity="0.9"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <v-btn icon class="mr-4" @click="errorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          Validation Dialog
        </v-card-title>

        <v-card-text style="overflow-y: scroll; max-height: 500px">
          <v-list>
            <v-list-group
              v-for="(item, index) in csvErrors"
              :key="index"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    >Row #: {{ item.data.rowNumber }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="text-left"
                    >{{ item.data.accountNumber }}
                    {{ item.data.name }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                class="px-4"
                v-for="(msg, i) in item.messages"
                :key="`${i}op`"
              >
                <v-list-item-icon>
                  <v-icon color="red darken-2">mdi-close-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap"
                    style="
                      inline-size: min-content !important;
                      overflow-rap: break-word !important;
                    "
                    v-text="msg"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <input
        type="file"
        ref="fileInput"
        show-size
        v-show="false"
        label="File input"
        @change="onUpload"
        id="uploader"
      />
      <v-card class="shadow-md">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-btn to="/app/reports" class="mb-4" depressed small
                  ><v-icon small>mdi-arrow-left</v-icon> Back</v-btn
                >
                <h3>Report Details</h3>
                <small class="grey--text text--darken-2"
                  >Reporting Period: {{ report.reportingPeriodEndDate }}</small
                >
                <v-breadcrumbs
                  class="pl-0"
                  :items="breadcrumbs"
                ></v-breadcrumbs>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <h4 class="grey--text text--darken-3">
                    Unique Message Identifier
                  </h4>
                  <p>{{ report.uniqueMessageIdentifier }}</p>
                </v-col>
                <v-col cols="6">
                  <h4 class="grey--text text--darken-3">
                    Message Type Indicator
                  </h4>
                  <p>{{ messageTypeMap[report.messageTypeIndicator] }}</p>
                </v-col>
                <v-col cols="6" v-if="report.institution">
                  <h4 class="grey--text text--darken-3">
                    Reporting Institution
                  </h4>
                  <p class="pb-0 mb-0">
                    {{ report.institution.taxIdentificationNumber }}
                  </p>
                  <b>{{ report.institution.name }}</b>
                  <address :href="`tel: ${report.institution.contactNumber}`">
                    {{
                      `${report.institution.street}, ${
                        report.institution.city
                      }, ${report.institution.countrySubEntity}, ${
                        countryMap[report.institution.country]
                      }`
                    }}
                  </address>
                </v-col>
                <v-col cols="6">
                  <h4 class="grey--text text--darken-3">Receiving Country</h4>
                  <p>{{ countryMap[report.recipientCountry] }}</p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col class="col-12 pa-0">
      <!-- Modals -->
      <ConfirmationModal
        ref="activeToggleConfirmationModal"
        :title="confirmationTitle"
        subtitle=""
        :message="confirmationMessage"
        confirmColor="error darken-1"
      />

      <v-card-title v-if="accounts.length != 0">
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue-grey"
              class="ma-2 white--text shadow-md"
              v-if="!isAdmin && !report.exported"
            >
              Upload
              <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="onUploadBtnClicked('individual')">
              <v-list-item-icon>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Upload Individual Accounts</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onUploadBtnClicked('organization')">
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                >Upload Organization Accounts</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              dark
              :loading="exporting"
              v-on="{ ...tooltip }"
              @click="onExportClicked"
              v-if="!isAdmin"
            >
              Export Report
              <v-icon>mdi-database-export-outline</v-icon>
            </v-btn>
          </template>
          <span>More Options</span>
        </v-tooltip>
      </v-card-title>
      <DataTableLayout
        class="shadow-md mx-2"
        :options="layoutOptions"
        @add="openForm"
        @search="search = $event"
      >
        <!-- Use to add addition actions such as buttons-->
        <template #actions> </template>

        <!-- content to display -->
        <template #content>
          <v-data-table
            class="elevation-0"
            :headers="headers"
            :loading="pageLoading"
            item-key="_id"
            :items="mostCurrentAccounts"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :custom-filter="filterAccounts"
            loading-text="Searching... Please wait"
          >
            <template v-slot:no-data>
              <img
                src="../../../assets/img/account_holder.svg"
                alt=""
                class="mx-auto mb-6 mt-12"
                style="display: block; width: 170px !important"
              />
              <h2 class="text-center">
                No accounts found. Please click the button below to upload the
                accounts for this report.
              </h2>

              <v-btn
                large
                depressed
                dark
                color="blue"
                class="my-6 mr-2 shadow-md"
                @click="onUploadBtnClicked('individual')"
                ><v-icon>mdi-upload</v-icon> Upload Individual Accounts</v-btn
              >
              <v-btn
                large
                depressed
                dark
                color="blue darken-1"
                class="my-6 shadow-md"
                @click="onUploadBtnClicked('organization')"
                ><v-icon>mdi-upload</v-icon> Upload Business Accounts</v-btn
              >
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-icon
                class="mr-2"
                color="blue darken-1"
                v-if="item.status == 'new'"
                >mdi-radiobox-marked</v-icon
              >
              <v-icon
                class="mr-2"
                color="green darken-1"
                v-if="item.status == 'corrected'"
                >mdi-check-underline-circle</v-icon
              >
              <v-icon
                v-if="item.status == 'deleted'"
                color="red darken-1"
                class="mr-2"
                >mdi-close-circle-outline</v-icon
              >
              <b>{{ item.status | capitalize }}</b>
            </template>
            <template v-slot:[`item.uniqueDocumentIdentifier`]="{ item }">
              <b>{{ item.uniqueDocumentIdentifier }}</b>
              <p class="pb-0 mb-0">{{ documentTypeMap[item.documentType] }}</p>
            </template>
            <template v-slot:[`item.accountBalance`]="{ item }">
              {{ item.accountBalance | currency }}
              {{ item.accountCurrency }}
            </template>
            <template v-slot:[`item.accountType`]="{ item }">
              {{ item.accountType | capitalize }}
            </template>
            <template v-slot:[`item.account`]="{ item }">
              <p class="pb-0 mb-0" v-if="!item.accountHolder.organizationName">
                {{
                  `${item.accountHolder.firstName} ${item.accountHolder.lastName}`
                }}
              </p>
              <p v-else class="pb-0 mb-0">
                {{ `${item.accountHolder.organizationName}` }}
              </p>
              <p>
                {{ item.accountNumber }}<br />
                {{ item.accountNumberType }}
              </p>
              <!-- <address>
                {{
                  `${item.accountHolder.address.street}, ${
                    item.accountHolder.address.city
                  }, ${item.accountHolder.address.countrySubEntity}, ${
                    countryMap[item.accountHolder.address.countryISOCode]
                  }`
                }}
              </address> -->
            </template>
            <template v-slot:[`item.user`]="{ item }">
              <span class="mr-2">{{ item.user.name }}</span> <br />
              <a :href="`tel: ${item.user.contactNumber}`">{{
                item.user.contactNumber
              }}</a>
              <br />
              <a :href="`mailto: ${item.user.email}`">{{ item.user.email }}</a>
            </template>

            <template v-slot:[`item.actions`]="{ index, item }">
              <DataTableActions
                v-if="item.status !== 'deleted'"
                :actions="actions"
                :data="{ index, item }"
              />
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Interest</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.interest | currency }}
                          {{ item.interestCurrency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Dividend</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.dividend | currency }}
                          {{ item.dividendCurrency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Gross Proceeds</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.grossProceeds | currency }}
                          {{ item.grossProceedsCurrency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>Other Payments</v-list-item-title>
                        <v-list-item-subtitle
                          >{{ item.otherPayments | currency }}
                          {{ item.otherPaymentsCurrency }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </template>
      </DataTableLayout>
    </v-col>
  </v-row>
</template>

<style scoped>
</style>

<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions, mapGetters } from 'vuex';
import { startCase } from 'lodash';

export default {
  mixins: [PageMixin],
  data: () => ({
    uploadPercentage: 0,
    loadingDialog: false,
    loadingMsg: '',
    exporting: false,
    file: null,
    report: {},
    errorDialog: false,
    expanded: [],
    singleExpand: true,
    pageLoading: true,
    confirmationTitle: '',
    confirmationMessage: '',
    search: '',

    csvErrors: [],

    layoutOptions: {
      hideSearch: false, // removes search button totally from view
      showSearch: false, // toggle search bar
      buttonText: 'Add Account',
      title: 'Accounts to be transmitted',
      subtitle: 'Lists reports to be submitted.'
    },
    uploadType: '',
    breadcrumbs: [
      {
        text: 'Reports',
        disabled: false,
        to: '/app/reports'
      },
      {
        text: '',
        disabled: true
      }
    ],

    actions: [],

    headers: [
      { text: 'Document Status', value: 'status', filterable: true },
      {
        text: 'Unique Document Identifier',
        value: 'uniqueDocumentIdentifier',
        filterable: true
      },
      { text: 'Account Type', value: 'accountType', filterable: true },
      { text: 'Account Details', value: 'account', filterable: true },
      { text: 'Account Balance', value: 'accountBalance', filterable: true },

      { text: 'Created By', value: 'user', filterable: true },
      { text: 'Actions', align: 'end', value: 'actions', sortable: false }
    ]
  }),

  async mounted() {
    this.actions = !this.user.institution
      ? []
      : [
          {
            icon: 'mdi-pencil-outline',
            action: 'Edit',
            actionCallBack: (index, item) => {
              this.openForm(item);
            }
          },
          {
            icon: 'mdi-pail-remove-outline',
            action: 'Delete',
            actionCallBack: (index, item) => {
              this.onDeleteClicked(item);
            }
          }
        ];
    this.layoutOptions.hideButton = !this.user.institution;
    try {
      this.pageLoading = true;
      const { id } = this.$route.params;

      this.breadcrumbs[1].text = id;

      this.report = await this.fetchReport(id);
      if (!this.report) return this.$router.push('/app/reports');

      await this.findAccountsByReportId(this.report.reportId);
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  computed: {
    ...mapGetters('coreModule', [
      'messageTypeMap',
      'countryMap',
      'addressTypes',
      'documentTypeMap'
    ]),
    ...mapGetters('reportModule', [
      'reports',
      'accounts',
      'mostCurrentAccounts'
    ])
  },

  methods: {
    ...mapActions('reportModule', [
      'fetchReport',
      'uploadAccounts',
      'findAccountsByReportId',
      'generateUniqueAccountID',
      'deleteAccount',
      'downloadXMLReport'
    ]),

    filterAccounts(value, search, item) {
      const haystack = `${item.uniqueDocumentIdentifier} ${item.accountHolder?.firstName} ${item.accountHolder?.lastName} ${item.accountHolder?.organizationName}`;
      return (
        search != null &&
        haystack.toLowerCase().indexOf(search?.toLowerCase()) !== -1
      );
    },

    async onExportClicked() {
      this.confirmationTitle = 'Are you sure you want to export this report?';
      this.confirmationMessage = `By exporting the report, any other edits to this report will be stored as a correction and a new unique document identifier will be generated.`;

      this.$refs.activeToggleConfirmationModal.show(
        async () => {
          try {
            this.exporting = true;
            await this.downloadXMLReport(this.report._id);
          } finally {
            this.exporting = false;
          }
        },
        () => {}
      );
    },

    async onUpload(ev) {
      try {
        this.uploadPercentage = 0;
        this.csvErrors.headers = [];

        const formData = new FormData();
        formData.append('file', ev.target.files[0]);

        this.loadingMsg = 'Uploading Accounts...';
        this.loadingDialog = true;
        await this.uploadAccounts({
          formData: formData,
          reportId: this.report._id,
          type: this.uploadType,
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            if (this.uploadPercentage == 100) {
              this.loadingMsg = 'Processing Accounts...';
            }
          }
        });

        await this.findAccountsByReportId(this.report.reportId);
      } catch (err) {
        if (err.response && err.response.status == 400) {
          const { data } = err.response;
          const account = data.message;

          this.csvErrors = account.map((el) => {
            let name = `${el.data?.accountHolder?.firstName || 'N/A'} ${
              el.data?.accountHolder?.lastName || 'N/A'
            }`;
            ('');
            if (this.uploadType !== 'individual') {
              name = `${el.data?.accountHolder?.organizationName || 'N/A'}`;
            }

            const data = {
              name,
              rowNumber: el.data.rowNumber,
              accountNumber: `${el.data.accountNumber}`
            };

            const messages = el.errors.map((el) =>
              el.message.replace(el.property, startCase(el.property))
            );

            return { messages, data };
          });

          this.errorDialog = true;
        }
        if (err.response && err.response.status == 422) {
          const { data } = err.response;
          this.showSnack({
            color: 'error',
            title: 'Please Resolve this to continue.',
            message: data.message[0] || 'Uknown error'
          });
        }
      } finally {
        this.loadingDialog = false;
        this.$refs.fileInput.value = '';
      }
    },

    async onUploadBtnClicked(type) {
      this.uploadType = type;
      document.getElementById('uploader').click();
    },

    async onDeleteClicked(item) {
      this.confirmationTitle = `Deleting this account will change the status of the overall report.`;
      this.confirmationMessage = `Are you sure you want to carry out this action?`;
      if (this.report.exported) {
        item.correctedDocumentReferenceIdentifier =
          item.uniqueDocumentIdentifier;
        item.uniqueDocumentIdentifier = await this.generateUniqueAccountID({
          account: item,
          user: this.user,
          reportMongoId: this.report._id
        });
      }
      return this.$refs.activeToggleConfirmationModal.show(async () => {
        this.deleteAccount({ ...item });
      });
    },

    openForm(item) {
      const route = item
        ? `/app/reports/${this.report._id}/account/${item._id}`
        : `/app/reports/${this.report._id}/account`;
      this.$router.push(route);
    },

    removeAccount(item) {
      this.confirmationTitle = 'Are you sure you want to remove this account?';
      this.confirmationMessage =
        'This action is permanent and cannot be undone.';

      this.$refs.activeToggleConfirmationModal.show(async () => {
        try {
          await this.deleteReport(item._id);
          this.showSnack({
            color: 'info',
            title: `Removed`,
            message: `Account ${item.name} was successfully removed.`
          });
        } catch (error) {
          this.httpErrorHandler(error);
        }
      });
    }
  }
};
</script>